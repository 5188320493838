// extracted by mini-css-extract-plugin
export var at1024__pr50p = "product-strategy-module--at1024__pr50p--1200f";
export var at640__pr50p = "product-strategy-module--at640__pr50p--554a3";
export var at768__w50p = "product-strategy-module--at768__w50p--52857";
export var bg__nobleGray = "product-strategy-module--bg__nobleGray--2bf60";
export var billetShadow = "product-strategy-module--billet-shadow--d8599";
export var c = "product-strategy-module--c--c64db";
export var cc18sf = "product-strategy-module--cc-18sf--fcad0";
export var cc199t = "product-strategy-module--cc-199t--198a8";
export var cc1ada = "product-strategy-module--cc-1ada--4b5f7";
export var cc1aev = "product-strategy-module--cc-1aev--9c616";
export var cc1lut = "product-strategy-module--cc-1lut--595a9";
export var cc1xry = "product-strategy-module--cc-1xry--d8407";
export var cc2hzo = "product-strategy-module--cc-2hzo--be616";
export var cc2n8m = "product-strategy-module--cc-2n8m--e9587";
export var cc4xbu = "product-strategy-module--cc-4xbu--4c67c";
export var ccCaj5 = "product-strategy-module--cc-caj5--d1189";
export var ccEw5j = "product-strategy-module--cc-ew5j--e77c0";
export var ccG73w = "product-strategy-module--cc-g73w--e6250";
export var ccKgeu = "product-strategy-module--cc-kgeu--b9986";
export var ccKv6t = "product-strategy-module--cc-kv6t--8e67e";
export var ccPjpe = "product-strategy-module--cc-pjpe--466d8";
export var ccUnoo = "product-strategy-module--cc-unoo--76b06";
export var childAs__seeMore = "product-strategy-module--childAs__seeMore--16684";
export var chips = "product-strategy-module--chips--bac35";
export var colorScheme__background__grayPale = "product-strategy-module--colorScheme__background__gray-pale--701dc";
export var colorScheme__buttonGold = "product-strategy-module--colorScheme__buttonGold--e517e";
export var colorScheme__buttonGold__darkBackground = "product-strategy-module--colorScheme__buttonGold__darkBackground--d62bc";
export var colorScheme__linkGold = "product-strategy-module--colorScheme__linkGold--afdd4";
export var colorScheme__whiteButton = "product-strategy-module--colorScheme__whiteButton--727bb";
export var coloredBackground__dark = "product-strategy-module--coloredBackground__dark--253e5";
export var coloredBackground__light = "product-strategy-module--coloredBackground__light--518dd";
export var common__article__section = "product-strategy-module--common__article__section--1af91";
export var common__article__section__doubleIndentBottom = "product-strategy-module--common__article__section__doubleIndentBottom--cffc4";
export var common__article__section__zeroIndentBottom = "product-strategy-module--common__article__section__zeroIndentBottom--0f0b5";
export var common__article__section__zeroIndentTop = "product-strategy-module--common__article__section__zeroIndentTop--35129";
export var common__chips = "product-strategy-module--common__chips--0d34f";
export var common__headline = "product-strategy-module--common__headline--539e6";
export var common__headline__beveledBottom = "product-strategy-module--common__headline__beveledBottom--cecfb";
export var common__headline__bgLoop = "product-strategy-module--common__headline__bgLoop--b918b";
export var common__leadQuote = "product-strategy-module--common__leadQuote--25718";
export var common__projectsTeaser = "product-strategy-module--common__projects-teaser--802cc";
export var common__scrollableTarget = "product-strategy-module--common__scrollableTarget--937bd";
export var common__teaser = "product-strategy-module--common__teaser--1a5c4";
export var common__teaser__button = "product-strategy-module--common__teaser__button--fed89";
export var common__textOutline = "product-strategy-module--common__textOutline--3fda9";
export var crispChatbox = "product-strategy-module--crisp-chatbox--c808d";
export var crispClient = "product-strategy-module--crisp-client--f3dec";
export var darkBackground = "product-strategy-module--darkBackground--32e3c";
export var darkTxt = "product-strategy-module--darkTxt--f2106";
export var fatButton = "product-strategy-module--fatButton--aeb3d";
export var fs1o5 = "product-strategy-module--fs1o5--f68d0";
export var fullWidth = "product-strategy-module--fullWidth--0e3c2";
export var goldBackground = "product-strategy-module--goldBackground--c42a8";
export var goldButton = "product-strategy-module--goldButton--622f5";
export var goldButton__casePorter = "product-strategy-module--goldButton__casePorter--cb32e";
export var goldButton__centered = "product-strategy-module--goldButton__centered--a44ea";
export var goldButton__w14 = "product-strategy-module--goldButton__w14--c6c31";
export var goldFatLink = "product-strategy-module--goldFatLink--617e6";
export var icomoon = "product-strategy-module--icomoon--349e9";
export var l = "product-strategy-module--l--125c7";
export var lineBreak = "product-strategy-module--lineBreak--55c4c";
export var lowercase = "product-strategy-module--lowercase--7caea";
export var maxw20 = "product-strategy-module--maxw20--f7944";
export var mb2 = "product-strategy-module--mb2--828b6";
export var mt2 = "product-strategy-module--mt2--a5f71";
export var nobr = "product-strategy-module--nobr--b4417";
export var primaryButton = "product-strategy-module--primaryButton--67659";
export var pt2 = "product-strategy-module--pt2--cc789";
export var r = "product-strategy-module--r--27acc";
export var reverse = "product-strategy-module--reverse--d2e6b";
export var sectionTitle = "product-strategy-module--section-title--a095d";
export var seeMore = "product-strategy-module--seeMore--543c1";
export var strategy = "product-strategy-module--strategy--f8a38";
export var strategyExpertise = "product-strategy-module--strategyExpertise--99f84";
export var textOutline = "product-strategy-module--textOutline--3b3bc";
export var transition = "product-strategy-module--transition--b3f32";
export var transitionBackground = "product-strategy-module--transition-background--3dedc";
export var transitionReverse = "product-strategy-module--transition-reverse--7f836";
export var upTo2cols = "product-strategy-module--upTo2cols--a7b34";
export var upTo2colsImgTxt = "product-strategy-module--upTo2colsImgTxt--8e06f";
export var uppercase = "product-strategy-module--uppercase--9b566";
export var v2022 = "product-strategy-module--v2022--6556b";
export var whiteButton = "product-strategy-module--whiteButton--93603";